import Vue from 'vue'

// axios
import { ToastPlugin } from 'bootstrap-vue'
import axios from 'axios'
import router from '@/router'

Vue.use(ToastPlugin)

let baseURL = 'https://melo-api.pensou.app.br/api/'

if (
  window.location.host.includes('hgl')
  || window.location.host.includes('192')
  || window.location.host.includes('localhost')
) {
  baseURL = 'https://melo-hgl-api.pensou.app.br/api/'
}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================

  baseURL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

// ℹ️ Add request interceptor to send the authorization header on each subsequent request after login
axiosIns.interceptors.request.use(config => {
  // Retrieve token from localStorage
  const token = localStorage.getItem('accessToken')

  // If token is found
  if (token) {
    // Get request headers and if headers is undefined assign blank object
    config.headers = config.headers || {}

    // Set authorization header
    // ℹ️ JSON.parse will convert token to string
    config.headers.Authorization = token ? `Bearer ${token}` : ''
  }

  // Return modified config
  return config
})

// ℹ️ Add response interceptor to handle 401 response
axiosIns.interceptors.response.use(
  response => response,
  error => {
    // Handle error
    console.log(error.response.status, 'erro 401')
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken')
      router.push('/login')
    } else {
      const toast = new Vue()
      const msg = error.response.data.message || 'Houve um erro contate o suporte'
      toast.$bvToast.toast(msg, {
        title: 'Erro',
        variant: 'danger',
        solid: true,
      })
      return Promise.reject(error)
    }
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
