export default [

  {
    path: '/clientes',
    name: 'clientes',
    component: () => import('@/views/apps/clientes/list/ClientesList.vue'),
    meta: {
      resource: 'clientes',
      action: 'listar',
    },
  },

  {
    path: '/clientes/edit/:id',
    name: 'clientes-edit',
    component: () => import('@/views/apps/clientes/edit/ClienteEdit.vue'),
    meta: {
      resource: 'clientes',
      action: 'listar',
    },
  },
  {
    path: '/leads',
    name: 'leads',
    component: () => import('@/views/apps/leads/list/ItemList.vue'),
    meta: {
      resource: 'leads',
      action: 'listar',
    },
  },

  {
    path: '/leads/edit/:id',
    name: 'leads-edit',
    component: () => import('@/views/apps/leads/edit/ItemEdit.vue'),
    meta: {
      resource: 'leads',
      action: 'listar',
    },
  },
  {
    path: '/atividades/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      resource: 'atividades',
      action: 'listar',
    },
    beforeEnter(to, _, next) {
      if (['Em_Desenvolvimento', 'Em_Aprovação', 'Reprovada', 'Concluída', 'Todas'].includes(to.params.filter)) {
        next()
      } else {
        next({ name: 'nao-encontrado' })
      }
    },
  },
  {
    path: '/atividades/todo/:filter',
    name: 'atividades-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      resource: 'atividades',
      action: 'listar',
    },
  },

  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      resource: 'chats',
      action: 'listar',
      setor: 'comercial',
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  {
    path: '/times',
    name: 'times',
    component: () => import('@/views/apps/times/list/ItensList.vue'),
    meta: {
      resource: 'times',
      action: 'listar',
      setor: 'engenharia',
    },
  },
  {
    path: '/comentarios/:modulo/:id',
    name: 'comentarios',
    component: () => import('@/views/apps/comentarios/view/ItemView.vue'),
    meta: {
      resource: 'comentarios',
      action: 'listar',
    },
  },
]
